<template>
  <div class="page">
    <div class="title">审批记录</div>

    <el-divider></el-divider>

    <!-- filter -->
    <div class="filter_box">
      <div class="filter_search">
        <!-- input -->
        <el-input
          class="filter_search_input"
          placeholder="请输入关键词"
          v-model="search.keyword"
          clearable
        >
        </el-input>
        <div class="custom_button no_select" @click="toSearch">搜索</div>
      </div>
      <!-- <div class="filter_btns">
                <div class="custom_button no_select">操作记录</div>
            </div> -->
    </div>

    <!-- table -->
    <el-table
      id="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
    >
      <el-table-column
        prop="sn"
        label="审批编号"
        min-width="150"
      ></el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        min-width="150"
      ></el-table-column>
      <el-table-column prop="department_str" label="所在部门"></el-table-column>
      <el-table-column prop="apply_user" label="发起人"></el-table-column>
      <el-table-column prop="apply_time" label="发起时间"></el-table-column>
      <el-table-column prop="apply_status" label="状态"></el-table-column>
      <el-table-column
        prop="next_staff_name"
        label="当前审批人"
      ></el-table-column>
      <el-table-column label="操作" min-width="120" fixed="right">
        <template v-slot:default="scope">
          <div class="operations">
            <div @click="closeApproval(scope.row.id)">关闭</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      search: {
        keyword: "",
        date: "",
      },
      tableData: [],
      // 分页参数
      page: {
        size: 15,
        page: 1,
        total: 0,
      },
      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("attendance", ["getApprovalList", "closeApprovalLog"]),

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keyword = keyword;
      }
      return () => {
        params.page = this.page.page;
        let loading = this.$loading();
        this.getApprovalList(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    /**
     * 关闭审批
     */
    closeApproval(id) {
      this.$confirm("关闭此审批, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.closeApprovalLog({ id })
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.data.msg,
            });
          })
          .catch((e) => {
            this.$message({
              type: "error",
              message: e.msg,
            });
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    font-size: 14px;
    color: #406bff;
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 13px;
    }
  }
}
</style>
